import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Breadcrumbs,
    Button,
    ButtonGroup,
    Fade,
    TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
    selectEmail,
    selectMaxAdditionalUsers,
    selectUserLoading,
    selectUserPermissions,
} from '../../store/reducers/userSlice';
import LoadPage from '../misc/LoadPage';
import { SubscriptionRequired } from '../misc/SubscriptionRequired';
import {
    getAdditionalEmails,
    selectAdditionalEmails,
    selectDraftAdditionalEmails,
    selectDraftCommitteePreferences,
    selectDraftIssuePreferences,
    selectDraftSectorPreferences,
    setDraftAdditionalEmails,
    setDraftIssueEmailPreferences,
} from '../../store/reducers/userPreferencesSlice';
import { useAppDispatch } from '../../store/store';
import {
    fetchCommitteesSelections,
    fetchSectorsSelections,
    selectChosenCommittees,
    selectChosenSectors,
} from '../../store/reducers/customSelectionSlice';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import {
    fetchCustomIssueList,
    fetchIssueList,
    selectCustomIssueList,
    selectIssueList,
} from '../../features/issue-dashboards/store/issueListSlice';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { emailPreferencesListComponent } from './EmailPreferencesListComponent';

const EmailPreferencesPage = () => {
    const dispatch = useAppDispatch();
    const userLoading = useSelector(selectUserLoading);
    const primaryEmail = useSelector(selectEmail);
    const permissions = useSelector(selectUserPermissions);
    const additionalEmails = useSelector(selectAdditionalEmails);
    const draftAdditionalEmails = useSelector(selectDraftAdditionalEmails);
    const draftIssuePreferences = useSelector(selectDraftIssuePreferences);
    const draftCommitteePreferences = useSelector(selectDraftCommitteePreferences);
    const draftSectorPreferences = useSelector(selectDraftSectorPreferences);
    const maxAdditionalUsers = useSelector(selectMaxAdditionalUsers);
    const sectorSelections = useSelector(selectChosenSectors);
    const committeeSelections = useSelector(selectChosenCommittees);
    const issueList = useSelector(selectIssueList);
    const persionalIssues = useSelector(selectCustomIssueList);
    const allIssues = useSelector(selectIssueList);

    const lobbyIQIssues = allIssues?.filter(
        (issue) => !persionalIssues.find((personalIssue) => personalIssue.code === issue.code)
    );
    const selectedLobbyIQIssues = lobbyIQIssues?.filter(
        (issue) => !!draftIssuePreferences?.find((preference) => preference.code === issue.code)
    );

    const [type, setType] = useState<'issue' | 'committee' | 'sector'>('issue');

    useEffect(() => {
        dispatch(getAdditionalEmails());
        dispatch(fetchSectorsSelections());
        dispatch(fetchCommitteesSelections());
        dispatch(fetchIssueList());
        dispatch(fetchCustomIssueList());
    }, [dispatch]);

    useEffect(() => {
        setDraftAdditionalEmails(additionalEmails);
    }, [additionalEmails]);

    const handleAdditonalEmailChange = (index: number, email: string, name: string) => {
        if (draftAdditionalEmails!.length <= index) {
            let newDraftAdditionalEmails = [...draftAdditionalEmails!];

            while (newDraftAdditionalEmails.length <= index) {
                if (newDraftAdditionalEmails.length === index) {
                    newDraftAdditionalEmails.push({ email, name });
                } else {
                    newDraftAdditionalEmails.push({ email: '', name: '' });
                }
            }
            dispatch(setDraftAdditionalEmails(newDraftAdditionalEmails));
        } else {
            dispatch(
                setDraftAdditionalEmails(
                    draftAdditionalEmails!.map((additionalEmail, i) => {
                        if (i === index) {
                            return { email, name };
                        } else {
                            return additionalEmail;
                        }
                    })
                )
            );
        }
    };

    const handleAddIssue = (issue: entityListExtra) => {
        dispatch(setDraftIssueEmailPreferences([...(draftIssuePreferences ?? []), { code: issue.code, emails: [], title: issue.title }]));
    };

    // const toggleEmailPreference = (preferenceList: EmailPreference[], code: string, email: string) => (
    //     preferenceList!.map((preference) => {
    //         if (preference.code.toString() === code) {
    //             if(preference.emails.find((e) => e.email === email)) {
    //             return { ...preference, emails: preference.emails.filter((e) => e.email !== email) };
    //         }
    //         return { ...preference, emails: [...preference.emails, { email, name: preference.emails.find((e) => e.email === email)?.name ?? '' }] };
    //     }
    //     return preference;
    // }));

    // const handleEmailPreferenceChange = (email: string, code: string) => {
    //     if(type === 'issue') {
    //         dispatch(setDraftIssueEmailPreferences(toggleEmailPreference(draftIssuePreferences!, code, email)));
    //     } else if(type === 'committee') {
    //         dispatch(setDraftCommitteeEmailPreferences(toggleEmailPreference(draftCommitteePreferences!, code, email)))
    //     } else if(type === 'sector') {
    //         dispatch(setDraftSectorEmailPreferences(draftSectorPreferences!.map((preference) => {
    //             if (preference.code.toString() === code) {
    //                 if(preference.emails.find((e) => e.email === email)) {
    //                     return { ...preference, emails: preference.emails.filter((e) => e.email !== email) };
    //                 }
    //                 return { ...preference, emails: [...preference.emails, { email, name: preference.emails.find((e) => e.email === email)?.name ?? '' }] };
    //             }
    //             return preference;
    //         })));
    //     }
    // };

    return userLoading ? (
        <LoadPage />
    ) : !permissions ? (
        <SubscriptionRequired />
    ) : (
        <Fade in={true}>
            <div className="lg:py-32 py-20 mx-auto lg:w-1/2 w-full flex flex-col lg:px-6 px-3">
                <Breadcrumbs separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    <div>Email Preferences</div>
                </Breadcrumbs>

                <div className="mt-16">
                    <div className="text-4xl flex flex-row gap-2">
                        Email Preferences
                    </div>
                </div>

                <div className="flex flex-col">
                    <div className="flex flex-col mt-12">
                        <div className="text-2xl flex flex-row">Primary User Email</div>
                    </div>
                    <div className="flex flex-row gap-2">
                        <div className="lg:w-80 min-w-56 lg:pr-4 md:pr-4 my-4">
                            <TextField
                                fullWidth
                                id={`email-primary`}
                                variant="outlined"
                                required
                                value={primaryEmail}
                                InputProps={{
                                    readOnly: true,
                                }}
                                inputProps={{
                                    style: { textAlign: 'center' }
                                }}
                            />  
                        </div>
                    </div>

                    <div className="flex flex-col mt-6">
                        <div className="text-2xl flex flex-row gap-2">
                            Additional Users
                        </div>
                    </div>
                    {[...Array(maxAdditionalUsers)].map((_, index) => (
                        <div className="flex flex-row gap-2">
                            <div
                                key={`email-${index}-container`}
                                className="lg:w-80 min-w-56 lg:pr-4 md:pr-4 my-4"
                            >
                                <TextField
                                    fullWidth
                                    id={`name-${index}`}
                                    placeholder="Name"
                                    variant="outlined"
                                    required
                                    value={
                                        draftAdditionalEmails &&
                                        draftAdditionalEmails.length > index
                                            ? draftAdditionalEmails[index].name
                                            : ''
                                    }
                                    onChange={(e) => {
                                        handleAdditonalEmailChange(
                                            index,
                                            draftAdditionalEmails![index]?.email ?? '',
                                            e.target.value
                                        );
                                    }}
                                />
                            </div>
                            <div
                                key={`name-${index}-container`}
                                className="lg:w-80 min-w-56 lg:pr-4 md:pr-4 my-4"
                            >
                                <TextField
                                    fullWidth
                                    id={`email-${index}`}
                                    placeholder="Email Recipient"
                                    variant="outlined"
                                    required
                                    value={
                                        draftAdditionalEmails &&
                                        draftAdditionalEmails.length > index
                                            ? draftAdditionalEmails[index].email
                                            : ''
                                    }
                                    onChange={(e) => {
                                        handleAdditonalEmailChange(
                                            index,
                                            e.target.value,
                                            draftAdditionalEmails![index]?.name ?? ''
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col mt-16">
                    <div className="text-2xl flex flex-row mb-3">
                        Set email preferences for Primary and Additional Users
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <ButtonGroup
                            disableElevation
                            size="medium"
                            color={
                                type === 'issue'
                                    ? 'info'
                                    : type === 'committee'
                                    ? 'secondary'
                                    : type === 'sector'
                                    ? 'success'
                                    : 'error'
                            }
                        >
                            <Button
                                onClick={() => setType('issue')}
                                variant={type === 'issue' ? 'contained' : 'outlined'}
                            >
                                Daily Issue Tracker Email
                            </Button>

                            <Button
                                onClick={() => setType('committee')}
                                variant={type === 'committee' ? 'contained' : 'outlined'}
                            >
                                Committee Meeting Transcripts
                            </Button>

                            <Button
                                onClick={() => setType('sector')}
                                variant={type === 'sector' ? 'contained' : 'outlined'}
                            >
                                Weekly Lobby Report Email
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>

                {emailPreferencesListComponent({
                    type,
                    displayList:
                        (type === 'issue'
                            ? issueList
                            : type === 'committee'
                            ? committeeSelections
                            : sectorSelections) ?? [],
                    preferences:
                        (type === 'issue'
                            ? draftIssuePreferences
                            : type === 'committee'
                            ? draftCommitteePreferences
                            : draftSectorPreferences) ?? [],
                    // Filter out empty emails
                    emails: primaryEmail
                        ? [
                              primaryEmail,
                              ...(draftAdditionalEmails
                                  ?.filter((user) => user.email !== '')
                                  .map((user) => user.email) ?? []),
                          ]
                        : [],
                    persionalIssues: persionalIssues ?? [],
                    lobbyIQIssues: lobbyIQIssues ?? [],
                    selectedLobbyIQIssues: selectedLobbyIQIssues ?? [],
                    handleEmailCheck: () => {},
                    handleAddIssue
                })}

                <div className="col-start-3 col-end-11 mt-12">
                    <div className="w-1/3">
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {}}
                            disabled={false}
                            endIcon={<SaveRoundedIcon />}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default EmailPreferencesPage;
