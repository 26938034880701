import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import moment from 'moment';
import IssueLobbyingDashboard from '../UI/IssueLobbyingDashboard';
import {
    fetchIssueInfo,
    fetchIssueOverview,
    fetchIssueRecentLobbying,
    fetchIssueRegistrations,
    fetchSentences,
    selectDPOHCluster,
    selectDPOHClusterLoading,
    selectFirmCluster,
    selectFirmClusterLoading,
    selectInstitutionCluster,
    selectInstitutionClusterLoading,
    selectIssueInfo,
    selectIssueInfoLoading,
    selectIssueOverview,
    selectIssueOverviewLoading,
    selectIssueRecentLobbying,
    selectIssueRecentLobbyingLoading,
    selectIssueRegistrations,
    selectIssueRegistrationsLoading,
    selectIssueSentences,
    selectIssueSentencesLoading,
    selectOrganizationCluster,
    selectOrganizationClusterLoading,
    selectSubjectCluster,
    selectSubjectClusterLoading,
    selectIssueExportFail,
    selectIssueExportLoading,
    fetchIssueUpdates,
    selectIssueUpdates,
    selectIssueUpdatesLoading,
    fetchKeywordMentions,
    selectIssueKeywordMentions,
    selectIssueKeywordMentionsLoading,
    selectSectorCluster,
    selectSectorClusterLoading,
    selectMentionCluster,
    selectMentionClusterLoading,
    fetchMentionCluster,
    fetchIssueTweets,
    selectIssueTweets,
    selectIssueTweetsLoading,
    selectIssueSocialOverview,
    selectIssueSocialOverviewLoading,
    fetchIssueSocialOverview,
    fetchIssueGovernmentAnnouncements,
    selectIssueGovernmentAnnouncements,
    selectIssueGovernmentAnnouncementsLoading,
    selectIssueSummary,
    selectIssueSummaryLoading,
    fetchIssueSummary,
    selectGazette,
    selectGazetteLoading,
    fetchGazette,
    fetchClusters,
} from '../store/issueSlice';
import { ProductType, selectUserPermissions } from '../../../store/reducers/userSlice';
import { SubscriptionRequired } from '../../../pages/misc/SubscriptionRequired';
import {
    entityCluster,
    entityLobbyingActivity,
    entityOverviews,
    entityRegistration,
} from '../../../interfaces/generic-entity.interface';
import { months } from '../../../constants/date_time';
import { SectorGovernmentInstitutions } from '../../sector-dashboards/properties/SectorGovernmentInstitutions';
import { SectorPublicOffice } from '../../sector-dashboards/properties/SectorPublicOffice';
import { SectorSubjects } from '../../sector-dashboards/properties/SectorSubjects';
import { SectorLobbyFirms } from '../../sector-dashboards/properties/SectorLobbyFirms';
import {
    governmentAnnouncement,
    IssueGazette,
    issueInfo,
    keywordMentions,
    Tweet,
} from '../interface/issue-entity.interface';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';
import { IssueMentions, IssueOrganizations, IssueSectors } from '../properties/Tooltips';
import IssueParlDashboard from '../UI/IssueParlDashboard';
import { Button, ButtonGroup } from '@mui/material';

const IssueDataLayer = () => {
    const dispatch = useAppDispatch();

    // Get the id of the current entity
    const urlParams = useParams();
    const entityCode = urlParams.entityId !== undefined ? urlParams.entityId : '';

    const [date, setDate] = useState<string>(moment(new Date()).format('YYYY-MM-DD'));
    const [displayLobbyingData, setDisplayLobbyingData] = useState<boolean>(true);
    const [sharedDataFetched, setSharedDataFetched] = useState<boolean>(false);
    const [lobbyingDataFetched, setLobbyingDataFetched] = useState<boolean>(false);
    const [parliamentaryDataFetched, setParliamentaryDataFetched] = useState<boolean>(false);

    const announcements: governmentAnnouncement[] | null = useSelector(
        selectIssueGovernmentAnnouncements
    );
    const announcementsLoading: boolean = useSelector(selectIssueGovernmentAnnouncementsLoading);

    const info: issueInfo | null = useSelector(selectIssueInfo);
    const infoLoading: boolean = useSelector(selectIssueInfoLoading);

    const overview: entityOverviews | null = useSelector(selectIssueOverview);
    const overviewLoading: boolean = useSelector(selectIssueOverviewLoading);

    const socialOverview: any[] | null = useSelector(selectIssueSocialOverview);
    const socialOverviewLoading: boolean = useSelector(selectIssueSocialOverviewLoading);

    const updates: recentUpdates | null = useSelector(selectIssueUpdates);
    const updatesLoading: boolean = useSelector(selectIssueUpdatesLoading);

    const tweets: Tweet[] | null = useSelector(selectIssueTweets);
    const tweetsLoading: boolean = useSelector(selectIssueTweetsLoading);

    const keywordMention: keywordMentions[] | null = useSelector(selectIssueKeywordMentions);
    const keywordMentionLoading: boolean = useSelector(selectIssueKeywordMentionsLoading);

    const sentences: any[] | null = useSelector(selectIssueSentences);
    const sentencesLoading: boolean = useSelector(selectIssueSentencesLoading);

    const activity: entityLobbyingActivity[] | null = useSelector(selectIssueRecentLobbying);
    const activityLoading: boolean = useSelector(selectIssueRecentLobbyingLoading);

    const registrations: entityRegistration[] | null = useSelector(selectIssueRegistrations);
    const registrationsLoading: boolean = useSelector(selectIssueRegistrationsLoading);

    const mentionCluster: entityCluster | null = useSelector(selectMentionCluster);
    const mentionClusterLoading: boolean = useSelector(selectMentionClusterLoading);

    const orgCluster: entityCluster | null = useSelector(selectOrganizationCluster);
    const orgClusterLoading: boolean = useSelector(selectOrganizationClusterLoading);

    const sectorCluster: entityCluster | null = useSelector(selectSectorCluster);
    const sectorClusterLoading: boolean = useSelector(selectSectorClusterLoading);

    const institutionCluster: entityCluster | null = useSelector(selectInstitutionCluster);
    const institutionClusterLoading: boolean = useSelector(selectInstitutionClusterLoading);

    const firmCluster: entityCluster | null = useSelector(selectFirmCluster);
    const firmClusterLoading: boolean = useSelector(selectFirmClusterLoading);

    const dpohCluster: entityCluster | null = useSelector(selectDPOHCluster);
    const dpohClusterLoading: boolean = useSelector(selectDPOHClusterLoading);

    const subjectCluster: entityCluster | null = useSelector(selectSubjectCluster);
    const subjectClusterLoading: boolean = useSelector(selectSubjectClusterLoading);

    const issue_summary = useSelector(selectIssueSummary);
    const issue_summaryLoading = useSelector(selectIssueSummaryLoading);

    const gazette: IssueGazette | null = useSelector(selectGazette);
    const gazetteLoading: boolean = useSelector(selectGazetteLoading);

    const export_fail = useSelector(selectIssueExportFail);
    const exportLoading = useSelector(selectIssueExportLoading);

    const permissions = useSelector(selectUserPermissions);

    const overviewHeaders: string[] = ['count'];
    // Get the current year
    const currentDate: moment.Moment = moment(date, 'YYYY-MM-DD');

    // Populate the list with the last 12 months
    for (let i = 0; i < 12; i++) {
        overviewHeaders.push(
            String(months[currentDate.month()]).slice(0, 3) +
                " '" +
                String(currentDate.year()).slice(2)
        );
        currentDate.subtract(1, 'month');
    }

    // Shared dispatches
    useEffect(() => {
        if (
            !sharedDataFetched &&
            (permissions?.issues || permissions?.productType === ProductType.TRIAL)
        ) {
            dispatch(fetchIssueInfo({ code: entityCode }));
            dispatch(fetchIssueUpdates({ code: entityCode, date: date }));
            // TODO: overview calls below need to split across both dashboards, but keep dispatch here
            dispatch(fetchIssueOverview({ code: entityCode, date: date }));
            dispatch(fetchIssueSocialOverview({ code: entityCode, date: date }));
            dispatch(fetchKeywordMentions({ code: entityCode, date: date }));
            setSharedDataFetched(true);
        }
    }, [dispatch, entityCode, date, permissions, sharedDataFetched]);

    // Lobbying dispatches
    useEffect(() => {
        if (
            displayLobbyingData &&
            !lobbyingDataFetched &&
            (permissions?.issues || permissions?.productType === ProductType.TRIAL)
        ) {
            dispatch(fetchIssueRecentLobbying({ code: entityCode, date: date }));
            dispatch(fetchIssueRegistrations({ code: entityCode, date: date }));
            dispatch(fetchClusters({ code: entityCode, date: date }));
            setLobbyingDataFetched(true);
        }
    }, [dispatch, entityCode, date, permissions, displayLobbyingData, lobbyingDataFetched]);

    // Parliamentary dispatches
    useEffect(() => {
        if (
            !displayLobbyingData &&
            !parliamentaryDataFetched &&
            (permissions?.issues || permissions?.productType === ProductType.TRIAL)
        ) {
            dispatch(fetchIssueGovernmentAnnouncements({ code: entityCode, date: date }));
            dispatch(fetchIssueTweets({ code: entityCode, date: date }));
            dispatch(fetchSentences({ code: entityCode, date: date }));
            dispatch(fetchMentionCluster({ code: entityCode, date: date }));
            dispatch(fetchGazette({ code: +entityCode, date: date }));
            dispatch(fetchIssueSummary({ code: entityCode, date: date }));
            setParliamentaryDataFetched(true);
        }
    }, [dispatch, entityCode, date, permissions, displayLobbyingData, parliamentaryDataFetched]);

    return permissions?.issues || permissions?.productType === ProductType.TRIAL ? (
        <div className="flex flex-col gap-4">
            <div
                className="flex flex-row justify-end pt-32 lg:px-20 px-4"
                onClick={() => setDisplayLobbyingData(!displayLobbyingData)}
            >
                <ButtonGroup disableElevation>
                    <Button
                        onClick={() => setDisplayLobbyingData(true)}
                        variant={displayLobbyingData ? 'contained' : 'outlined'}
                    >
                        Lobbying Data
                    </Button>
                    <Button
                        onClick={() => setDisplayLobbyingData(false)}
                        variant={!displayLobbyingData ? 'contained' : 'outlined'}
                    >
                        Parliamentary Data
                    </Button>
                </ButtonGroup>
            </div>

            {displayLobbyingData ? (
                <IssueLobbyingDashboard
                    date={date}
                    handleDateChange={setDate}
                    info={info}
                    infoLoading={infoLoading}
                    announcements={announcements}
                    announcementsLoading={announcementsLoading}
                    updates={updates}
                    updatesLoading={updatesLoading}
                    issueSummary={issue_summary}
                    issueSummaryLoading={issue_summaryLoading}
                    tweets={tweets}
                    tweetsLoading={tweetsLoading}
                    keywordMentions={keywordMention}
                    keywordMentionsLoading={keywordMentionLoading}
                    overview={overview}
                    overviewLoading={overviewLoading}
                    socialOverview={socialOverview}
                    socialOverviewLoading={socialOverviewLoading}
                    recentLobbying={activity}
                    recentLobbyingLoading={activityLoading}
                    recentRegistrations={registrations}
                    recentRegistrationsLoading={registrationsLoading}
                    bumperData={sentences}
                    bumperDataLoading={sentencesLoading}
                    gazetteData={gazette}
                    gazetteLoading={gazetteLoading}
                    exportFail={export_fail}
                    exportLoading={exportLoading}
                    clusters={[
                        {
                            cluster_data: mentionCluster,
                            title: 'MP Mentions of issue',
                            tooltipContent: IssueMentions,
                            loading: mentionClusterLoading,
                        },
                        {
                            cluster_data: sectorCluster,
                            title: 'Sectors Lobbying on Issue',
                            tooltipContent: IssueSectors,
                            loading: sectorClusterLoading,
                        },
                        {
                            cluster_data: orgCluster,
                            title: 'Organizations Lobbying on Issue',
                            tooltipContent: IssueOrganizations,
                            loading: orgClusterLoading,
                        },
                        {
                            cluster_data: institutionCluster,
                            title: 'Institutions Lobbied on Issue',
                            tooltipContent: SectorGovernmentInstitutions,
                            loading: institutionClusterLoading,
                        },
                        {
                            cluster_data: dpohCluster,
                            title: 'DPOHs Lobbied on Issue',
                            tooltipContent: SectorPublicOffice,
                            loading: dpohClusterLoading,
                        },
                        {
                            cluster_data: subjectCluster,
                            title: 'Subjects of Lobbying Communications',
                            tooltipContent: SectorSubjects,
                            loading: subjectClusterLoading,
                        },
                        {
                            cluster_data: firmCluster,
                            title: 'Lobby Firms',
                            tooltipContent: SectorLobbyFirms,
                            loading: firmClusterLoading,
                        },
                    ]}
                />
            ) : (
                <IssueParlDashboard
                    date={date}
                    handleDateChange={setDate}
                    info={info}
                    infoLoading={infoLoading}
                    announcements={announcements}
                    announcementsLoading={announcementsLoading}
                    updates={updates}
                    updatesLoading={updatesLoading}
                    issueSummary={issue_summary}
                    issueSummaryLoading={issue_summaryLoading}
                    tweets={tweets}
                    tweetsLoading={tweetsLoading}
                    keywordMentions={keywordMention}
                    keywordMentionsLoading={keywordMentionLoading}
                    overview={overview}
                    overviewLoading={overviewLoading}
                    socialOverview={socialOverview}
                    socialOverviewLoading={socialOverviewLoading}
                    recentLobbying={activity}
                    recentLobbyingLoading={activityLoading}
                    recentRegistrations={registrations}
                    recentRegistrationsLoading={registrationsLoading}
                    bumperData={sentences}
                    bumperDataLoading={sentencesLoading}
                    gazetteData={gazette}
                    gazetteLoading={gazetteLoading}
                    exportFail={export_fail}
                    exportLoading={exportLoading}
                    clusters={[
                        {
                            cluster_data: mentionCluster,
                            title: 'MP Mentions of issue',
                            tooltipContent: IssueMentions,
                            loading: mentionClusterLoading,
                        },
                        {
                            cluster_data: sectorCluster,
                            title: 'Sectors Lobbying on Issue',
                            tooltipContent: IssueSectors,
                            loading: sectorClusterLoading,
                        },
                        {
                            cluster_data: orgCluster,
                            title: 'Organizations Lobbying on Issue',
                            tooltipContent: IssueOrganizations,
                            loading: orgClusterLoading,
                        },
                        {
                            cluster_data: institutionCluster,
                            title: 'Institutions Lobbied on Issue',
                            tooltipContent: SectorGovernmentInstitutions,
                            loading: institutionClusterLoading,
                        },
                        {
                            cluster_data: dpohCluster,
                            title: 'DPOHs Lobbied on Issue',
                            tooltipContent: SectorPublicOffice,
                            loading: dpohClusterLoading,
                        },
                        {
                            cluster_data: subjectCluster,
                            title: 'Subjects of Lobbying Communications',
                            tooltipContent: SectorSubjects,
                            loading: subjectClusterLoading,
                        },
                        {
                            cluster_data: firmCluster,
                            title: 'Lobby Firms',
                            tooltipContent: SectorLobbyFirms,
                            loading: firmClusterLoading,
                        },
                    ]}
                />
            )}
        </div>
    ) : (
        <SubscriptionRequired />
    );
};

export default IssueDataLayer;
