import { Autocomplete, Checkbox, Fade, FormControlLabel, Grow, IconButton, TextField } from "@mui/material";
import { Link } from 'react-router-dom';
import { issueEditorItem } from "../../features/issue-dashboards/interface/issue-entity.interface";
import { entityListExtra } from "../../interfaces/generic-entity.interface";
import { EmailPreference } from "../../store/reducers/userPreferencesSlice";
import {
    Selection,
} from '../../store/reducers/customSelectionSlice';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteIcon from '@mui/icons-material/Delete';

interface EmailPreferencesListComponentProps {
    type: 'sector' | 'committee' | 'issue';
    displayList: entityListExtra[] | Selection[];
    preferences: EmailPreference[];
    emails: string[];
    persionalIssues: issueEditorItem[];
    lobbyIQIssues: entityListExtra[];
    selectedLobbyIQIssues: entityListExtra[];
    handleEmailCheck: (email: string, code: string) => void;
    handleAddIssue: (issue: entityListExtra) => void;
}

export const emailPreferencesListComponent = (props: EmailPreferencesListComponentProps) => {
    const {
        type,
        displayList,
        preferences,
        emails,
        persionalIssues,
        lobbyIQIssues,
        selectedLobbyIQIssues,
        handleEmailCheck,
        handleAddIssue,
    } = props;

    const issueList = lobbyIQIssues.filter(
        (issue) => !selectedLobbyIQIssues.find((selectedIssue) => selectedIssue.code === issue.code)
    );

    // TODO make util function?
    const renderOptions = (props: React.HTMLAttributes<HTMLLIElement>, option: entityListExtra) => {
        return (
            <Fade in={true} key={option.code || option.title}>
                <li {...props} style={{ fontSize: 14 }}>
                    {option.title}
                </li>
            </Fade>
        );
    };

    return (
        <div className="flex flex-col mt-8">
            <div className="text-2xl flex flex-row mb-4 items-center">
                {type === 'sector'
                    ? 'Weekly Lobby Report Email'
                    : type === 'committee'
                    ? 'Committee Meeting Transcripts'
                    : 'Daily Issue Tracker Email'}
            </div>
            {type === 'issue' ? (
                <div>
                    <div className="lg:w-3/4 w-full">
                        <div className="flex flex-col gap-2">
                            {persionalIssues.map((entity) => (
                                <Grow in={true}>
                                    <div className="flex sm:flex-row flex-col justify-between bg-white shadow-sm rounded-md p-3 my-2 text-sm text-blue-400 font-medium">
                                        <Link
                                            to={`/sectors/${entity.code}?name=${encodeURIComponent(
                                                entity.title
                                            )}`}
                                            className="text-lg hover:underline underline-offset-2 my-auto mx-3"
                                        >
                                            {entity.title}
                                        </Link>
                                        <div className="flex flex-row gap-2">
                                            <div className="flex flex-col pr-12">
                                                {emails?.map((email) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={true}
                                                                onChange={() => {
                                                                    /* TODO: Implement onChange handler */
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <div className="text-black text-sm">
                                                                {email}
                                                            </div>
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Grow>
                            ))}
                            {selectedLobbyIQIssues.map((entity) => (
                                <Grow in={true}>
                                    <div className="flex sm:flex-row flex-col justify-between bg-white shadow-sm rounded-md p-3 my-2 text-sm text-blue-400 font-medium">
                                        <Link
                                            to={`/sectors/${entity.code}?name=${encodeURIComponent(
                                                entity.title
                                            )}`}
                                            className="text-lg hover:underline underline-offset-2 my-auto mx-3"
                                        >
                                            {entity.title}
                                        </Link>
                                        <div className="flex flex-row gap-2">
                                            <div className="flex flex-col">
                                                {emails?.map((email) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={true}
                                                                onChange={() => {
                                                                    /* TODO: Implement onChange handler */
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <div className="text-black text-sm">
                                                                {email}
                                                            </div>
                                                        }
                                                    />
                                                ))}
                                            </div>

                                            <IconButton
                                                onClick={() => {
                                                    // TODO
                                                }}
                                                aria-label="delete"
                                            >
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </div>
                                    </div>
                                </Grow>
                            ))}
                        </div>
                    </div>
                    <div className="lg:w-3/4 w-full">
                        <Grow in={true}>
                            <div className="flex sm:flex-row flex-col bg-white shadow-sm w-full items-center justify-between rounded-md p-3 my-2 text-blue-400 font-medium gap-5">
                                <div className="flex flex-row items-center gap-2 mb-2 sm:mb-0">
                                    <AddToPhotosIcon />
                                    Add LobbyIQ Issue
                                </div>
                                <Autocomplete
                                    className="lg:w-72 w-56"
                                    isOptionEqualToValue={(
                                        option: entityListExtra,
                                        value: entityListExtra
                                    ) => option.title === value.title}
                                    disableListWrap
                                    size="small"
                                    onChange={(_, value) => {
                                        if (value) {
                                            handleAddIssue(value);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Issue" />
                                    )}
                                    options={issueList || []}
                                    getOptionLabel={(option: any) => option.title ?? ''}
                                    renderOption={renderOptions}
                                />
                            </div>
                        </Grow>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col mt-16">
                    <div className="lg:w-3/4 w-full mx-auto">
                        <div className="flex flex-col gap-2">
                            {displayList.map((entity) => (
                                <Grow in={true}>
                                    <div className="flex sm:flex-row flex-col justify-between bg-white shadow-sm rounded-md p-3 my-2 text-sm text-blue-400 font-medium">
                                        <Link
                                            to={`/${type}s/${entity.code}?name=${encodeURIComponent(
                                                entity.title
                                            )}`}
                                            className="text-lg hover:underline underline-offset-2 my-auto mx-3"
                                        >
                                            {entity.title}
                                        </Link>
                                        <div className="flex flex-col">
                                            {emails?.map((email) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={preferences.find((p) => p.code === entity.code)?.emails.find((e) => e.email === email) !== undefined}
                                                            onChange={() => {
                                                                handleEmailCheck(email, entity.code.toString());
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <div className="text-black text-sm">
                                                            {email}
                                                        </div>
                                                    }
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </Grow>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};