import { Link, useNavigate } from 'react-router-dom';
import MetaTag from '../../components/metatag-component/MetaTag';
import Carousel from 'react-material-ui-carousel';
import { Button } from '@mui/material';

const IssueTrackFeatures = () => {
    const navigate = useNavigate();

    return (
        <div className="xl:mx-24 mx-8 ">
            <MetaTag
                title="Issue Tracker"
                keywords="Monitor Lobbying by Subject,Custom Issue Dashboards,Keyword Alerts,Weekly Issue Emails,Monitor Lobbying, Subject Dashboards, Real-Time Metrics, Text Analytics, Federal Lobby Registry, Policy Issues, Custom Issue Dashboards, Keyword Alerts, Reputation Risk, Crisis Management, Weekly Issue Emails, Lobby Communications, Parliamentary Discussions, Registration Texts"
                description="Track and monitor federal lobbying by subject with real-time metrics, custom issue dashboards, and keyword alerts. Receive weekly summaries and alerts on critical policy issues and lobbying communications for comprehensive reputation risk and crisis management."
            />

            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>

            <div className="lg:grid grid-cols-12 grid-rows-auto gap-6 py-8 flex flex-col">
                <div className="col-start-1 row-start-1 col-span-12 text-left">
                    <h1 className="lg:text-4xl text-3xl">Issue Tracker</h1>
                </div>

                <div className="lg:col-start-1 lg:col-end-7 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between gap-5">
                    <div>
                        <div className="text-2xl">
                            <span className="text-liq-orange hover:underline">
                                <Link to="/issues">Custom Issue</Link>
                            </span>{' '}
                            Dashboards
                        </div>
                        <div className="text-md mt-8 font-light">
                            LobbyIQ's unique custom issue dashboards allow clients to easily define
                            key issues and monitor their discussion in parliament. LobbyIQ
                            automatically tracks mentions of issue keywords in Hansard, House
                            Committees, Senate Committees, Committee Briefs, News Releases, and
                            Lobby Registrations. Issue dashboards also track stakeholder lobbying on
                            issues, based on keywords included in lobby registrations.
                        </div>
                    </div>
                    <div className="mx-auto mb-5">
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => navigate('/trial/issue')}
                        >
                            <div className="font-sans text-xl text-white normal-case">
                                Sign up for free trial
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="lg:col-start-1 lg:col-end-4 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between">
                    <div>
                        <div className="text-2xl">Daily Keyword Alerts</div>
                        <div className="text-md mt-8 font-light">
                            Clients can receive daily emails whenever issue keywords are mentioned
                            in Hansard, House Committees, Senate Committees, Stakeholder Briefs,
                            News Releases, or Lobby Registrations.
                        </div>
                    </div>
                </div>

                <div className="lg:col-start-4 lg:col-end-7 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between">
                    <div>
                        <div className="text-2xl">Weekly Lobby Report</div>
                        <div className="text-md mt-8 font-light">
                            LobbyIQ sends a weekly email summary of stakeholder lobbying on each
                            issue.
                        </div>
                    </div>
                </div>

                <div className="col-start-7 col-span-6 row-start-2 row-end-4 flex flex-col justify-start items-center">
                    <Carousel className="w-full" animation="slide">
                        <div className="bg-white rounded-xl w-full h-full">
                            <img
                                src={process.env.PUBLIC_URL + '/Issue_Tracker_3.png'}
                                className="w-full h-full"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                        <div className="bg-white rounded-xl w-full h-full">
                            <img
                                src={process.env.PUBLIC_URL + '/Issue_Tracker_2.png'}
                                className="w-full h-full"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                        <div className="bg-white rounded-xl w-full h-full">
                            <img
                                src={process.env.PUBLIC_URL + '/Issue_Tracker_1.png'}
                                className="w-full h-full"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                    </Carousel>
                </div>
            </div>

            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>
        </div>
    );
};

export default IssueTrackFeatures;
