import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Table, { default as MUITable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Autocomplete, TablePagination, TableSortLabel, TextField, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import Modal from '../../../components/misc/Modal';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';

const StyledTableCell = styled(TableCell)({
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: 14,
    verticalAlign: 'top',
});

// rowData and rowHeaders are required parameters
interface tableProps {
    rowData: Array<any>;
    alignment?: 'right' | 'center' | 'left' | 'inherit' | 'justify' | undefined;
    defaultSort?: string;
    defaultSortOrder?: 'asc' | 'desc';
    defaultNumRows?: number;
    paginated?: boolean;
    embedded?: boolean;
    simplePagination?: boolean;
    disableSorting?: boolean;
    suffixes?: any;
    externalSpyRowsPerPage?: (rowNum: number) => void;
    externalSpyPage?: (pageNum: number) => void;
    externalSpySortedData?: (data: Array<any>) => void;
    trackClickEvent?: (name: string) => void;
    filterBy: string;
}

interface innerRow {
    row: any;
    maxSentences: number;
    filter: string[];
    filterBy: string;
    trackClickEvent: (name: string) => void;
}

const Row = ({ row, maxSentences, filter, filterBy, trackClickEvent }: innerRow) => {
    const rowData = row.sentences;

    // Use a Set to store unique names
    const uniqueNames = new Set();

    // Iterate through each object in the array
    rowData.forEach((entry: any) => {
        // Add the name to the set
        uniqueNames.add(entry.name);
    });

    // Convert the set to an array
    const uniqueNamesArray = Array.from(uniqueNames);

    const [filterValues, setFilterValues] = useState<string[]>([]);

    const [open, setOpen] = useState<boolean>(false);

    const [orderBy, setOrderBy] = useState<string>('date');
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const sortedRowData = useCallback(
        () =>
            [...rowData].sort((a: any, b: any) => {
                if (order === 'asc') {
                    if (typeof a[orderBy] === 'string') return a[orderBy] > b[orderBy] ? 1 : -1;
                    else return +a[orderBy] > +b[orderBy] ? 1 : -1;
                } else {
                    if (typeof a[orderBy] === 'string') return a[orderBy] < b[orderBy] ? 1 : -1;
                    else return +a[orderBy] < +b[orderBy] ? 1 : -1;
                }
            }),
        [order, orderBy, rowData]
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSort = (property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
    };

    const handleFilterValuesChange = (event: any, newValue: any) => {
        setFilterValues(newValue);
    };

    const filteredRowData = useCallback(
        () =>
            [...sortedRowData()].filter((entry: any) => {
                if (filterValues.length > 0) return filterValues.includes(entry.name);
                else return true;
            }),
        [filterValues, sortedRowData]
    );

    return (
        <div className="flex flex-col min-w-[500px] lg-w-auto">
            <div className="flex flex-row justify-between items-start gap-2 mb-2">
                {row.title.startsWith('URL') ? (
                    <Link
                        className="text-sky-600 hover:underline underline-offset-2"
                        to={row.title.split('#')[2]}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                            trackClickEvent(`Table redirect by URL: ${row.title.split('#')[1]}`)
                        }
                    >
                        {row.title.split('#')[1]}
                    </Link>
                ) : (
                    <div className="text-slate-600">{row.title}</div>
                )}
                <button
                    className="text-slate-600 flex-shrink-0 flex gap-2"
                    onClick={() => setOpen(true)}
                >
                    {rowData.length > maxSentences && maxSentences > 0
                        ? `View ${rowData.length - maxSentences} more`
                        : 'View all'}
                    <OpenInFullRoundedIcon fontSize="small" color="action" />
                </button>
            </div>

            {maxSentences > 0 ? (
                rowData
                    .filter((entry: any) => filter.length === 0 || filter.includes(entry.name))
                    .slice(0, maxSentences)
                    .map((entry: any, idx: number) => {
                        return (
                            <span key={idx} className="ml-4 m-1 p-1 bg-slate-100 rounded-md">
                                {entry.name}:{' '}
                                {entry.sentence.link ? (
                                    <Link
                                        className="text-sky-600 hover:underline underline-offset-2"
                                        to={entry.sentence.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() =>
                                            trackClickEvent(`Table redirect by URL: ${entry.link}`)
                                        }
                                    >
                                        {entry.sentence.text}
                                    </Link>
                                ) : (
                                    entry.sentence.text
                                )}
                            </span>
                        );
                    })
            ) : (
                <></>
            )}

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                width={90}
                title={
                    'Sentences from: ' +
                    (row.title.startsWith('URL') ? row.title.split('#')[1] : row.title)
                }
            >
                <Autocomplete
                    className="pt-2 pb-8"
                    multiple
                    value={filterValues}
                    onChange={handleFilterValuesChange}
                    options={uniqueNamesArray}
                    getOptionLabel={(option: any) => option}
                    filterSelectedOptions
                    renderInput={(params: any) => (
                        <TextField {...params} variant="standard" label={`Filter ${filterBy}`} />
                    )}
                />
                <div className="h-[60vh] overflow-auto">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'name'}
                                        onClick={() => {
                                            handleSort('name');
                                        }}
                                        direction={orderBy === 'name' ? order : 'asc'}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Sentence</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRowData()
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((entry: any, idx: number) => {
                                    return (
                                        <TableRow key={idx}>
                                            <TableCell>{entry.name}</TableCell>
                                            <TableCell>
                                                <span>
                                                    {entry.sentence.link ? (
                                                        <Link
                                                            className="text-sky-600 hover:underline underline-offset-2"
                                                            to={entry.sentence.link}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            onClick={() =>
                                                                trackClickEvent(
                                                                    `Table redirect by URL: ${entry.link}`
                                                                )
                                                            }
                                                        >
                                                            {entry.sentence.text}
                                                        </Link>
                                                    ) : (
                                                        entry.sentence.text
                                                    )}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    labelRowsPerPage={'Rows:'}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
                    }
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={filterValues.length > 0 ? filteredRowData().length : rowData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Modal>
        </div>
    );
};

const IssueTable = ({
    rowData,
    alignment = 'right',
    defaultNumRows = 5,
    paginated = false,
    embedded = false,
    simplePagination = false,
    disableSorting = false,
    // optional Spy functions to track change of these parameters in the parent component
    externalSpyRowsPerPage = undefined,
    externalSpyPage = undefined,
    externalSpySortedData = undefined,
    trackClickEvent = (name: string) => {},
    filterBy,
}: tableProps) => {
    const meetingNamesMap = useMemo(() => new Map(), []);
    // Use a Set to store unique names across all meetings
    const uniqueNamesGlobal = new Set();

    // Iterate through each meeting in the array
    rowData.forEach((entry: any) => {
        // Add the name to the set
        if (entry.sentences.length > 0) {
            entry.sentences.forEach((sentence: any) => {
                uniqueNamesGlobal.add(sentence.name);
                if (meetingNamesMap.has(entry.title)) {
                    // Get the array associated with the key
                    let array = meetingNamesMap.get(entry.title);
                    // Add the value to the array
                    array.push(sentence.name);
                } else {
                    // If the key doesn't exist, create a new array with the value
                    meetingNamesMap.set(entry.title, [sentence.name]);
                }
            });
        }
    });

    // Convert the set to an array
    const uniqueNamesGlobalArray = Array.from(uniqueNamesGlobal);

    const [filterValues, setFilterValues] = useState<string[]>([]);

    const [orderBy, setOrderBy] = useState<string>('date');
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultNumRows);

    const [maxSentences, setMaxSentences] = useState<number>(3);

    const handleMaxSentencesChange = (event: { target: { value: string } }) => {
        const value = parseInt(event.target.value);
        setMaxSentences(value);
    };

    const sortedRowData = useCallback(
        () =>
            [...rowData].sort((a: any, b: any) => {
                if (order === 'asc') {
                    if (typeof a[orderBy] === 'string') return a[orderBy] > b[orderBy] ? 1 : -1;
                    else return +a[orderBy] > +b[orderBy] ? 1 : -1;
                } else {
                    if (typeof a[orderBy] === 'string') return a[orderBy] < b[orderBy] ? 1 : -1;
                    else return +a[orderBy] < +b[orderBy] ? 1 : -1;
                }
            }),
        [order, orderBy, rowData]
    );

    useEffect(() => {
        externalSpySortedData && externalSpySortedData(sortedRowData());
    }, [externalSpySortedData, sortedRowData]);

    const handleChangePage = (event: unknown, newPage: number) => {
        externalSpyPage && externalSpyPage(newPage);
        trackClickEvent(`Table page change: (${page} to ${newPage})`);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        externalSpyRowsPerPage && externalSpyRowsPerPage(+event.target.value);
        externalSpyPage && externalSpyPage(0);
        trackClickEvent(`Table row change: (${rowsPerPage} to ${+event.target.value})`);

        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSort = (property: any) => {
        if (!disableSorting) {
            const isAsc = orderBy === property && order === 'asc';
            setOrderBy(property);
            setOrder(isAsc ? 'desc' : 'asc');
        }
    };

    const handleFilterValuesChange = (event: any, newValue: any) => {
        setFilterValues(newValue);
    };

    const filteredRowData = useCallback(
        () =>
            [...sortedRowData()].filter((entry: any) => {
                if (filterValues.length > 0)
                    return filterValues.some((item: any) =>
                        meetingNamesMap.get(entry.title).includes(item)
                    );
                else return true;
            }),
        [filterValues, sortedRowData, meetingNamesMap]
    );

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

    return (
        <div className={`${!embedded && 'bg-white shadow-md rounded-md min-h-full'} overflow-auto`}>
            <div className="p-4">
                <div className="flex flex-col items-start md:flex-row md:justify-between gap-3">
                    <div className="lg:w-1/2 w-full">
                        <Autocomplete
                            multiple
                            value={filterValues}
                            onChange={handleFilterValuesChange}
                            options={uniqueNamesGlobalArray}
                            getOptionLabel={(option: any) => option}
                            filterSelectedOptions
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    label={`Filter by ${filterBy}`}
                                />
                            )}
                        />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <TextField
                            type="number"
                            value={maxSentences}
                            onChange={handleMaxSentencesChange}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    style: {
                                        width: `${maxSentences.toString().length + 3}ch`,
                                        height: 20,
                                    },
                                },
                            }}
                            size="small"
                            variant="outlined"
                        />{' '}
                        sentences
                    </div>
                </div>
            </div>
            <TableContainer>
                <MUITable size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align={alignment}>
                                <TableSortLabel
                                    active={orderBy === 'date'}
                                    onClick={() => {
                                        trackClickEvent('Table sort by: date');
                                        handleSort('date');
                                    }}
                                    direction={orderBy === 'date' ? order : 'asc'}
                                >
                                    Date
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align={alignment}>
                                <TableSortLabel
                                    active={orderBy === 'committee'}
                                    onClick={() => {
                                        trackClickEvent('Table sort by: committee');
                                        handleSort('committee');
                                    }}
                                    direction={orderBy === 'committee' ? order : 'asc'}
                                >
                                    Committee
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align={alignment}>
                                <TableSortLabel
                                    active={orderBy === 'title'}
                                    onClick={() => {
                                        trackClickEvent('Table sort by: title');
                                        handleSort('title');
                                    }}
                                    direction={orderBy === 'title' ? order : 'asc'}
                                >
                                    Title
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRowData()
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, idx) => {
                                return (
                                    <TableRow key={idx}>
                                        <StyledTableCell align="left">
                                            <div className="min-w-[6em]">{row.date}</div>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.committee}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Row
                                                row={row}
                                                maxSentences={maxSentences}
                                                key={idx}
                                                trackClickEvent={trackClickEvent}
                                                filter={filterValues}
                                                filterBy={filterBy}
                                            />
                                        </StyledTableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 33 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={Object.keys(rowData[0]).length} />
                            </TableRow>
                        )}
                        {rowData.length === 0 && (
                            <TableRow
                                style={{
                                    height: 33 * 5,
                                }}
                            >
                                <TableCell colSpan={5}>
                                    <div className="text-center">No data</div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </MUITable>
            </TableContainer>
            <div className={paginated ? '' : 'hidden'}>
                <TablePagination
                    labelRowsPerPage={simplePagination ? '' : 'Rows:'}
                    labelDisplayedRows={({ from, to, count }) =>
                        simplePagination
                            ? ''
                            : `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
                    }
                    rowsPerPageOptions={simplePagination ? [] : [5, 10, 25, 50]}
                    component="div"
                    count={filterValues.length > 0 ? filteredRowData().length : rowData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

export default IssueTable;
