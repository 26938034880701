import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import {
    CommitteeListItem,
    fetchSenateAndHouseCommitteeList,
    selectCombinedCommitteeList,
} from '../../features/committee-dashboards/data/committeeListSlice';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { Breadcrumbs, Button, ButtonGroup, Checkbox, FormControlLabel, Grow } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom';
import SelectionModal from './SelectionModal';

import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import {
    fetchSectorList,
    selectSectorList,
} from '../../features/sector-dashboards/store/sectorListSlice';
import {
    fetchInstitutionList,
    selectInstitutionList,
} from '../../features/institution-dashboards/store/institutionListSlice';
import {
    selectUserGracePeriod,
    selectUserLoading,
    selectUserPermissions,
} from '../../store/reducers/userSlice';
import LoadPage from '../misc/LoadPage';
import { SubscriptionRequired } from '../misc/SubscriptionRequired';
import {
    Selection,
    fetchCommitteeEmailPreferences,
    fetchCommitteesSelections,
    fetchInstitutionEmailPreferences,
    fetchInstitutionsSelections,
    fetchNewsletterSelections,
    fetchSectorEmailPreferences,
    fetchSectorsSelections,
    selectChosenCommittees,
    selectChosenInstitutions,
    selectChosenSectors,
    selectCommitteeEmailPreferences,
    selectInstitutionEmailPreferences,
    selectSectorEmailPreferences,
    updateCommitteeEmailPreferences,
    updateInstitutionEmailPreferences,
    updateSectorEmailPreferences,
} from '../../store/reducers/customSelectionSlice';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';

const SelectionsPage = () => {
    const dispatch = useAppDispatch();

    const userLoading = useSelector(selectUserLoading);
    const userGracePeriod = useSelector(selectUserGracePeriod);
    const permissions = useSelector(selectUserPermissions);
    const sectors = useSelector(selectSectorList);
    const selectedSectors = useSelector(selectChosenSectors);
    const sectorEmailPreferences = useSelector(selectSectorEmailPreferences);
    const institutions = useSelector(selectInstitutionList);
    const selectedInstitutions = useSelector(selectChosenInstitutions);
    const institutionEmailPreferences = useSelector(selectInstitutionEmailPreferences);
    const committees = useSelector(selectCombinedCommitteeList);
    const committeeEmailPreferences = useSelector(selectCommitteeEmailPreferences);
    const selectedCommittees = useSelector(selectChosenCommittees);

    const [type, setType] = useState<'sectors' | 'institutions' | 'committees'>('committees');

    const [selectionOpen, setSelectionOpen] = useState<boolean>(false);

    // TODO refresh after render
    let owned: {
        sectors: Selection[] | null;
        sectorsEmails: Selection[] | null;
        institutions: Selection[] | null;
        institutionsEmails: Selection[] | null;
        committees: Selection[] | null;
        committeesEmails: Selection[] | null;
    } = {
        sectors: selectedSectors,
        sectorsEmails: sectorEmailPreferences,
        institutions: selectedInstitutions,
        institutionsEmails: institutionEmailPreferences,
        committees: selectedCommittees,
        committeesEmails: committeeEmailPreferences,
    };

    const available: {
        sectors: entityListExtra[] | null;
        institutions: entityListExtra[] | null;
        committees: CommitteeListItem[] | null;
    } = {
        sectors,
        institutions,
        committees,
    };

    useEffect(() => {
        dispatch(fetchSectorList());
        dispatch(fetchInstitutionList());
        dispatch(fetchSenateAndHouseCommitteeList());
        dispatch(fetchSectorsSelections());
        dispatch(fetchInstitutionsSelections());
        dispatch(fetchCommitteesSelections());
        dispatch(fetchNewsletterSelections());
        dispatch(fetchSectorEmailPreferences());
        dispatch(fetchInstitutionEmailPreferences());
        dispatch(fetchCommitteeEmailPreferences());
    }, [dispatch]);

    const canEditSelection = () => {
        if (userGracePeriod && Date.parse(userGracePeriod) > Date.now()) {
            return true;
        }
        if (type === 'sectors' && permissions) {
            return (
                !selectedSectors.find(
                    (sector) =>
                        sector.dateLastModified &&
                        new Date(sector.dateLastModified).getMonth() === new Date().getMonth() &&
                        new Date(sector.dateLastModified).getFullYear() === new Date().getFullYear()
                ) || selectedSectors.length < permissions[type]
            );
        } else if (type === 'committees' && permissions) {
            return (
                !selectedCommittees.find(
                    (committee) =>
                        committee.dateLastModified &&
                    new Date(committee.dateLastModified).getMonth() === new Date().getMonth() &&
                    new Date(committee.dateLastModified).getFullYear() === new Date().getFullYear()
                ) || selectedCommittees.length < permissions[type]
            );
        } else if (type === 'institutions' && permissions) {
            return (
                !selectedInstitutions.find(
                    (institution) =>
                        institution.dateLastModified &&
                    new Date(institution.dateLastModified).getMonth() === new Date().getMonth() &&
                    new Date(institution.dateLastModified).getFullYear() === new Date().getFullYear()
                ) || selectedInstitutions.length < permissions[type]
            );
        }
    };

    const handleEmailPreferenceChange = (selection: Selection) => {
        if (type === 'sectors') {
            const newSelections = !!sectorEmailPreferences.find(
                (preference) => preference.code === selection.code
            )
                ? sectorEmailPreferences.filter((sector) => sector.code !== selection.code)
                : [...sectorEmailPreferences, selection];
            dispatch(updateSectorEmailPreferences(newSelections));
        }
        if (type === 'institutions') {
            const newSelections = !!institutionEmailPreferences.find(
                (preference) => preference.code === selection.code
            )
                ? institutionEmailPreferences.filter(
                      (preference) => preference.code !== selection.code
                  )
                : [...institutionEmailPreferences, selection];
            dispatch(updateInstitutionEmailPreferences(newSelections));
        }
        if (type === 'committees') {
            const newSelections = !!committeeEmailPreferences.find(
                (preference) => preference.code === selection.code
            )
                ? committeeEmailPreferences.filter(
                      (preference) => preference.code !== selection.code
                  )
                : [...committeeEmailPreferences, selection];
            dispatch(updateCommitteeEmailPreferences(newSelections));
        }
    };

    return userLoading ? (
        <LoadPage />
    ) : !permissions ? (
        <SubscriptionRequired />
    ) : (
        <Fade in={true}>
            <div className="lg:py-32 py-20 grid grid-cols-12">
                <Breadcrumbs className="col-start-2 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    <div>Dashboard Selections</div>
                </Breadcrumbs>

                <SelectionModal
                    open={selectionOpen}
                    availableList={available[type] || []}
                    ownedList={owned[type] || []}
                    maxEntitlements={permissions[type]}
                    type={type}
                    handleClose={() => setSelectionOpen(false)}
                />

                <div className="col-start-3 col-end-11 mt-16">
                    <div className="text-2xl flex flex-row gap-2 items-center">
                        Dashboard Selections
                    </div>
                </div>

                <div className="col-start-3 col-end-11 mt-6">
                    <div className="flex flex-row justify-between items-center">
                        <div className="text-lg flex flex-row gap-2 items-center">
                            Your Dashboards
                        </div>
                        <ButtonGroup
                            disableElevation
                            size="small"
                            color={
                                type === 'sectors'
                                    ? 'success'
                                    : type === 'institutions'
                                    ? 'info'
                                    : type === 'committees'
                                    ? 'secondary'
                                    : 'error'
                            }
                        >
                            {permissions.sectors < 100 && (
                                <Button
                                    onClick={() => setType('sectors')}
                                    variant={type === 'sectors' ? 'contained' : 'outlined'}
                                >
                                    Sectors
                                </Button>
                            )}
                            {permissions.institutions < 100 && (
                                <Button
                                    onClick={() => setType('institutions')}
                                    variant={type === 'institutions' ? 'contained' : 'outlined'}
                                >
                                    Institutions
                                </Button>
                            )}
                            {permissions.committees < 100 && (
                                <Button
                                    onClick={() => setType('committees')}
                                    variant={type === 'committees' ? 'contained' : 'outlined'}
                                >
                                    Committees
                                </Button>
                            )}
                        </ButtonGroup>
                    </div>
                    <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                    <div className="flex flex-row justify-between items-center py-2 text-md text-slate-500">
                        {canEditSelection() ? (
                            <>
                                Click Edit to select {permissions[type]} {type}
                            </>
                        ) : (
                            <p>
                                {replace_underscores_capitalize(type.slice(0, -1))} selections may
                                be changed again after the 1st day of each month
                            </p>
                        )}
                        <Button
                            onClick={() => setSelectionOpen(true)}
                            endIcon={<EditNoteRoundedIcon />}
                            size="medium"
                            variant="contained"
                            color={
                                type === 'sectors'
                                    ? 'success'
                                    : type === 'institutions'
                                    ? 'info'
                                    : type === 'committees'
                                    ? 'secondary'
                                    : 'error'
                            }
                            disabled={!canEditSelection()}
                        >
                            Edit
                        </Button>
                    </div>
                    <div>
                        {owned[type]?.map((entity) => (
                            <Grow in={true}>
                                <div className="flex flex-row justify-between bg-white shadow-sm rounded-md p-3 my-2 text-sm text-blue-400 font-medium">
                                    <Link
                                        to={`/${
                                            entity.title.slice(0, 3) === 'S -' ? 'senate/' : ''
                                        }${type}/${entity.code}?name=${encodeURIComponent(
                                            entity.title
                                        )}`}
                                        className="hover:underline underline-offset-2 my-auto"
                                    >
                                        {entity.code} - {entity.title}
                                    </Link>
                                    {(type === 'sectors' || type === 'committees') && <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    !!owned[`${type}Emails`]?.find(
                                                        (emailSelection: Selection) =>
                                                            entity.code === emailSelection.code
                                                    )
                                                }
                                                onChange={() => handleEmailPreferenceChange(entity)}
                                            />
                                        }
                                        label={
                                            <div className="text-black text-sm">
                                                {type === 'sectors' ? 'Include in weekly lobby communications email' : 'Receive committee meeting transcripts'}
                                            </div>
                                        }
                                    />}
                                </div>
                            </Grow>
                        ))}
                        {[...Array(permissions[type] - (owned[type] || []).length)].map(() => (
                            <Grow in={true}>
                                <div className="border border-1 border-slate-300 rounded-md p-3 my-2 text-sm text-slate-300 font-medium">
                                    Add another {type.slice(0, -1)}
                                </div>
                            </Grow>
                        ))}
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default SelectionsPage;
