import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';

export interface EmailPreference {
    emails: AdditionalEmail[];
    code: number;
    title: string;
    expiryDate?: Date;
}

interface AdditionalEmail {
    email: string;
    name: string;
}

interface UserPreferencesState {
    additionalEmails: AdditionalEmail[];
    additionalEmailsLoading: boolean;
    draftAdditionalEmails?: AdditionalEmail[];
    draftIssueEmailPreferences?: EmailPreference[];
    draftCommitteeEmailPreferences?: EmailPreference[];
    draftSectorEmailPreferences?: EmailPreference[];
};

const initialState: UserPreferencesState = {
    additionalEmails: [],
    additionalEmailsLoading: false,
    draftAdditionalEmails: [],
    draftIssueEmailPreferences: [],
    draftCommitteeEmailPreferences: [],
    draftSectorEmailPreferences: [],
};

const baseURI = process.env.REACT_APP_API_BASE_URL;

export const getAdditionalEmails = createAsyncThunk(
    'user/getAdditionalEmails',
    async () => {
        const response = await axios.get(`${baseURI}/user/preferences/emails`);
        return response.data;
    }
);

export const setAdditionalEmails = createAsyncThunk(
    'user/setAdditionalEmails',
    async (additionalEmails: string[]) => {
        await axios.post(`${baseURI}/user/preferences/emails`, { additionalEmails });
    }
);

export const getIssueEmailPreferences = createAsyncThunk(
    'user/getIssueEmailPreferences',
    async () => {
        const response = await axios.get(`${baseURI}/user/preferences/issues`);
        return response.data;
    }
);

export const getCommitteeEmailPreferences = createAsyncThunk(
    'user/getCommitteeEmailPreferences',
    async () => {
        const response = await axios.get(`${baseURI}/user/preferences/committees`);
        return response.data;
    }
);

export const getSectorEmailPreferences = createAsyncThunk(
    'user/getSectorEmailPreferences',
    async () => {
        const response = await axios.get(`${baseURI}/user/preferences/sectors`);
        return response.data;
    }
);

const userPreferencesSlice = createSlice({
    name: 'userPreferences',
    initialState,
    reducers: {
        setDraftAdditionalEmails(state, action: PayloadAction<AdditionalEmail[]>) {
            state.draftAdditionalEmails = action.payload;
        },
        setDraftIssueEmailPreferences(state, action: PayloadAction<EmailPreference[]>) {
            state.draftIssueEmailPreferences = action.payload;
        },
        setDraftCommitteeEmailPreferences(state, action: PayloadAction<EmailPreference[]>) {
            state.draftCommitteeEmailPreferences = action.payload;
        },
        setDraftSectorEmailPreferences(state, action: PayloadAction<EmailPreference[]>) {
            state.draftSectorEmailPreferences = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAdditionalEmails.pending, (state) => {
            state.additionalEmailsLoading = true;
        });
        builder.addCase(getAdditionalEmails.fulfilled, (state, action) => {
            state.additionalEmails = action.payload;
            state.draftAdditionalEmails = action.payload;
            state.additionalEmailsLoading = false;
        });
        builder.addCase(getIssueEmailPreferences.fulfilled, (state, action) => {
            state.draftIssueEmailPreferences = action.payload;
        });
        builder.addCase(getCommitteeEmailPreferences.fulfilled, (state, action) => {
            state.draftCommitteeEmailPreferences = action.payload;
        });
        builder.addCase(getSectorEmailPreferences.fulfilled, (state, action) => {
            state.draftSectorEmailPreferences = action.payload;
        });
    },
});

export const { setDraftAdditionalEmails, setDraftIssueEmailPreferences, setDraftCommitteeEmailPreferences, setDraftSectorEmailPreferences } = userPreferencesSlice.actions;

export const selectAdditionalEmails = (state: RootState) => state.userPreferences.additionalEmails;
export const selectDraftAdditionalEmails = (state: RootState) => state.userPreferences.draftAdditionalEmails;
export const selectDraftIssuePreferences = (state: RootState) => state.userPreferences.draftIssueEmailPreferences;
export const selectDraftCommitteePreferences = (state: RootState) => state.userPreferences.draftCommitteeEmailPreferences;
export const selectDraftSectorPreferences = (state: RootState) => state.userPreferences.draftSectorEmailPreferences;

export default userPreferencesSlice.reducer;