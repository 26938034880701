import React from 'react';
import { Logout } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import FeedIcon from '@mui/icons-material/Feed';
import TuneIcon from '@mui/icons-material/Tune';
import { Divider, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { Permissions, ProductType } from '../../store/reducers/userSlice';

// userEmail and userloading are required parameters to determine account info to display
interface accountPanelProps {
    userEmail: string | null;
    userLoading: boolean;
    userPermissions?: Permissions;
    handleLogin: () => Promise<void>;
    handleLogout: () => void;
    handleClose: () => void;
}

const AccountPanel = ({
    userEmail,
    userLoading,
    userPermissions,
    handleLogout,
    handleClose,
}: accountPanelProps) => {
    const link_tran = 'text-slate-600 transition ease-in-out hover:text-black';
    const spacing = 'w-full flex flex-row items-center justify-between';

    const hasDashboardSelections = () => {
        if (userPermissions) {
            if (
                userPermissions.productType === ProductType.CORPORATE ||
                userPermissions.productType === ProductType.PROFESSIONAL
            ) {
                return true;
            }
            if (
                userPermissions.productType === ProductType.ENTERPRISE &&
                (userPermissions.committees < 100 ||
                    userPermissions.institutions < 100 ||
                    userPermissions.sectors < 100)
            ) {
                return true;
            }
        }
        return false;
    };

    return (
        <div>
            {userEmail && !userLoading && (
                <div>
                    <div className="text-slate-600 flex flex-row justify-center px-8 p-4 min-w-[250px]">
                        {userEmail}
                    </div>
                    <Divider sx={{ marginY: 1 }} />
                    {userPermissions &&
                        userPermissions.productType !== ProductType.PROFESSIONAL &&
                        userPermissions.productType !== ProductType.TRIAL && (
                            <div className={link_tran}>
                                <MenuItem
                                    onClick={handleClose}
                                    component={Link}
                                    to="/account/custom"
                                >
                                    <div className={spacing}>
                                        Manage Custom Issues
                                        <TuneIcon style={{ fontSize: 20 }} />
                                    </div>
                                </MenuItem>
                            </div>
                        )}
                    {/* <div className={link_tran}>
                        <MenuItem
                            onClick={handleClose}
                            component={Link}
                            to="/account/emails"
                        >
                            <div className={spacing}>
                                Email Preferences
                                <Email style={{ fontSize: 20 }} />
                            </div>
                        </MenuItem>
                    </div> */}
                    {userPermissions && userPermissions.productType === ProductType.ENTERPRISE && (
                        <div className={link_tran}>
                            <MenuItem
                                onClick={handleClose}
                                component={Link}
                                to="/account/enterprise"
                            >
                                <div className={spacing}>
                                    Authorized users
                                    <PersonIcon style={{ fontSize: 20 }} />
                                </div>
                            </MenuItem>
                        </div>
                    )}
                    {hasDashboardSelections() && (
                        <div className={link_tran}>
                            <MenuItem
                                onClick={handleClose}
                                component={Link}
                                to="/account/selections"
                            >
                                <div className={spacing}>
                                    Dashboard Selections
                                    <FeedIcon style={{ fontSize: 20 }} />
                                </div>
                            </MenuItem>
                        </div>
                    )}

                    <div className={link_tran + ' mt-2'}>
                        <MenuItem onClick={handleLogout}>
                            <div className={spacing}>
                                Logout
                                <Logout style={{ fontSize: 18 }} color="error" />
                            </div>
                        </MenuItem>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountPanel;
