import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import { Breadcrumbs, Button, TextField } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom';

import {
    ChildUser,
    ProductType,
    fetchChildUsers,
    selectActionLoading,
    selectMaxAdditionalUsers,
    selectChildUsers,
    selectEmail,
    selectIsParentUser,
    selectLastChildModified,
    selectUserGracePeriod,
    selectUserPermissions,
    updateChildUsers,
} from '../../store/reducers/userSlice';
import LoadPage from '../misc/LoadPage';
import logo from '../../assets/vectors/LIQ_badge.png';
import { SubscriptionRequired } from '../misc/SubscriptionRequired';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import validator from 'validator';
import Modal from '../../components/misc/Modal';

const EnterpriseSettings = () => {
    const dispatch = useAppDispatch();

    const actionLoading = useSelector(selectActionLoading);
    const userGracePeriod = useSelector(selectUserGracePeriod);
    const lastModifyDate = useSelector(selectLastChildModified);
    const isParentUser = useSelector(selectIsParentUser);
    const maxAdditionalUsers = useSelector(selectMaxAdditionalUsers);
    const permissions = useSelector(selectUserPermissions);
    const childUsers = useSelector(selectChildUsers);
    const parentEmail = useSelector(selectEmail);

    const [isError, setIsError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const [draftChildren, editDraftChildren] = useState<ChildUser[]>(
        Array.apply(null, Array(maxAdditionalUsers)).map(() => ({
            email: '',
            firstName: '',
            lastName: '',
        }))
    );

    useEffect(() => {
        dispatch(fetchChildUsers());
    }, [dispatch]);

    useEffect(() => {
        editDraftChildren(
            Array.apply(null, Array(maxAdditionalUsers)).map((_, index) =>
                childUsers && childUsers?.length > index
                    ? childUsers[index]
                    : {
                          email: '',
                          firstName: '',
                          lastName: '',
                      }
            )
        );
    }, [childUsers, maxAdditionalUsers]);

    const canEditSelection = () => {
        //todo able to remove anytime, but adding only once per month
        if (
            (userGracePeriod && Date.parse(userGracePeriod) > Date.now()) ||
            (lastModifyDate && lastModifyDate < new Date().getMonth()) ||
            (draftChildren &&
                childUsers &&
                draftChildren.filter((child) => child.email !== '').length < childUsers!.length)
        ) {
            return true;
        }
    };

    const handleChildFirstNameUpdate = (value: string, index: number) => {
        const newInputValues = [...draftChildren];
        newInputValues[index] = {
            email: newInputValues[index].email,
            firstName: value,
            lastName: newInputValues[index].lastName,
        };
        editDraftChildren(newInputValues);
    };

    const handleChildLastNameUpdate = (value: string, index: number) => {
        const newInputValues = [...draftChildren];
        newInputValues[index] = {
            email: newInputValues[index].email,
            firstName: newInputValues[index].firstName,
            lastName: value,
        };
        editDraftChildren(newInputValues);
    };

    const handleChildEmailUpdate = (value: string, index: number) => {
        const newInputValues = [...draftChildren];
        newInputValues[index] = {
            email: value,
            firstName: newInputValues[index].firstName,
            lastName: newInputValues[index].lastName,
        };
        editDraftChildren(newInputValues);
    };

    const handleOpenConfirmation = () => {
        setIsError(false);
        setIsEmailError(false);

        const validEntries = draftChildren.filter(
            (child) =>
                child.email !== '' &&
                validator.isEmail(child.email) &&
                child.firstName !== '' &&
                child.lastName !== ''
        );

        const emptyEntries = draftChildren.filter(
            (child) => child.email === '' && child.firstName === '' && child.lastName === ''
        );

        if (validEntries.length + emptyEntries.length === draftChildren.length) {
            const validEmails = validEntries.filter(
                (child) => child.email.split('@')[1] === parentEmail!.split('@')[1]
            );

            if (validEmails.length === validEntries.length) {
                setConfirmationOpen(true);
            } else {
                setIsEmailError(true);
            }
        } else {
            setIsError(true);
        }
    };

    const handleSaveChildren = async () => {
        setConfirmationOpen(false);
        dispatch(updateChildUsers(draftChildren));
    };

    return actionLoading ? (
        <LoadPage />
    ) : !permissions ? (
        <SubscriptionRequired />
    ) : permissions.productType === ProductType.ENTERPRISE && isParentUser ? (
        <Fade in={true}>
            <div className="lg:py-32 py-20 grid grid-cols-12">
                <Breadcrumbs className="col-start-2 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    <div>Authorized Users</div>
                </Breadcrumbs>

                <div className="col-start-2 col-end-12 mt-16">
                    <div className="text-2xl flex flex-row gap-2 items-center">
                        Authorized Users
                    </div>
                    <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                </div>
                <div className="col-start-2 col-end-7 bg-white shadow-sm rounded-md p-2">
                    {draftChildren.map((child, index) => (
                        <div className="p-3 my-2 text-sm text-blue-400 font-medium">
                            <div className="flex flex-row justify-between gap-2">
                                <TextField
                                    fullWidth
                                    autoComplete="off"
                                    id={'childFirstName' + index}
                                    placeholder="First Name"
                                    variant="outlined"
                                    onChange={(e) =>
                                        handleChildFirstNameUpdate(e.target.value, index)
                                    }
                                    value={child ? child.firstName : ''}
                                    error={
                                        isError &&
                                        child.firstName === '' &&
                                        (child.lastName !== '' || child.email !== '')
                                    }
                                />
                                <TextField
                                    fullWidth
                                    autoComplete="off"
                                    id={'childLastName' + index}
                                    placeholder="Last Name"
                                    variant="outlined"
                                    onChange={(e) =>
                                        handleChildLastNameUpdate(e.target.value, index)
                                    }
                                    value={child ? child.lastName : ''}
                                    error={
                                        isError &&
                                        child.lastName === '' &&
                                        (child.firstName !== '' || child.email !== '')
                                    }
                                />
                                <TextField
                                    fullWidth
                                    autoComplete="off"
                                    id={'childEmail' + index}
                                    placeholder="Business email"
                                    variant="outlined"
                                    onChange={(e) => handleChildEmailUpdate(e.target.value, index)}
                                    value={child ? child.email : ''}
                                    error={
                                        (isError || isEmailError) &&
                                        ((!validator.isEmail(child.email) &&
                                            (child.firstName !== '' || child.lastName !== '')) ||
                                            (child.email.split('@')[1] !==
                                                parentEmail!.split('@')[1] &&
                                                validator.isEmail(child.email)))
                                    }
                                />
                            </div>
                        </div>
                    ))}
                    {(isError || isEmailError) && (
                        <div className="pl-3 text-red-700 text-lg">
                            {isEmailError
                                ? 'Please ensure all emails use the same business handle'
                                : 'Please ensure all fields contain a first and last name and a valid email, or are empty.'}
                        </div>
                    )}

                    <div className="p-3">
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleOpenConfirmation}
                            disabled={!canEditSelection()}
                            endIcon={<SaveRoundedIcon />}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <div className="col-start-7 col-end-12 p-2">
                    <div className="mx-5 text-lg">
                        <p>
                            Add or delete additional authorized users in the company who are
                            included in the Enterprise subscription.
                        </p>
                        <br />
                        <p>
                            Additional users must be employees of the company. Email addresses must
                            be business emails.
                        </p>
                        <br />
                        <p>Authorized Users may be added only once per month.</p>
                        <br />
                        <p>Press Save once complete.</p>
                    </div>
                </div>
                <Modal
                    open={confirmationOpen}
                    onClose={() => setConfirmationOpen(false)}
                    title={'Confirm Change'}
                    width={25}
                >
                    <div className="flex flex-col">
                        <div className="mb-5">
                            Authorized Users may be added only once per month.
                        </div>

                        <div className="font-bold">Authorized Users:</div>
                        <div className="flex flex-col my-5">
                            {draftChildren.some((child) => child.email !== '') ? (
                                draftChildren.map(
                                    (child, index) =>
                                        child.email !== '' && (
                                            <div
                                                className="bg-slate-100 rounded my-1 px-2"
                                                key={index}
                                            >
                                                {child.email}
                                            </div>
                                        )
                                )
                            ) : (
                                <div>none</div>
                            )}
                        </div>
                    </div>
                    <div className="flex content-center">
                        <Button fullWidth variant={'contained'} onClick={handleSaveChildren}>
                            Confirm
                        </Button>
                    </div>
                </Modal>
            </div>
        </Fade>
    ) : (
        <div className="flex flex-row items-center justify-center py-[50vh] text-lg">
            <img src={logo} className="h-20" alt="logo" />
            <div style={{ marginLeft: '20px', marginRight: '20px' }} />
            <p>Unable to view Enterprise Settings</p>
        </div>
    );
};

export default EnterpriseSettings;
